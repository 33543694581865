<template>
  <div class="index-page">
    <div v-if="gameserverIndex != null" class="py-8">
      <div class="container">
        <common-breadcrumb
          class="mb-8 mt-xl-8 mb-xl-16"
          :crumbs="breadCrumbs"
        />
      </div>
      <div
        v-if="gameserverIndex.trustpilot.backgroundImage !== null"
        class="index-page__head"
      >
        <div
          class="index-page__head-inner d-flex justify-content-center flex-column position-relative"
        >
          <div
            class="index-page__head-info container py-4 text-center text-md-center"
          >
            <h2>{{ gameserverIndex.trustpilot.headline }}</h2>
            <h1 class="index-page__trustpilot-promo-text mx-auto">
              {{ gameserverIndex.trustpilot.promoText }}
            </h1>
            <div
              class="d-flex justify-content-center index-page__trustpilot-wrapper align-items-center"
            >
              <div class="index-page__trustpilot-icons me-5">
                <common-trust-pilot-stars :score="trustpilotScore.trustScore" />
              </div>
              <div class="index-page__trustpilot-link">
                <a
                  href="https://www.trustpilot.com/review/www.g-portal.com"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Trustpilot"
                >
                  <nuxt-img
                    alt="Trustpilot Logo"
                    class="figure-img index-page__trustpilot-logo"
                    width="175"
                    height="43"
                    preload
                    src="/sc/images/trustpilot/logo.svg"
                  />
                </a>
              </div>
            </div>
          </div>

          <div class="index-page__background">
            <strapi-image
              v-if="gameserverIndex.trustpilot.backgroundImage"
              :alt-fallback="`${gameserverIndex.trustpilot.headline} Background`"
              :image="gameserverIndex.trustpilot.backgroundImage"
              :lazy="false"
              auto-scale
              fluid
            />
          </div>
        </div>
      </div>

      <div
        v-if="
          gameserverIndex.countdown != null && gameserverIndex.countdown.endDate
        "
        class="index-page__countdown-wrapper"
      >
        <strapi-countdown :component="gameserverIndex.countdown" />
      </div>
      <gameserver-index-top-games
        class="index-page__top-games"
        :products="topProducts"
      />

      <div class="container mt-16 mt-md-20 mb-28 text-center">
        <h1 class="position-relative mb-8">
          {{ gameserverIndex.headline }}
        </h1>

        <div
          class="text-center"
          v-html="md.render(gameserverIndex.description)"
        ></div>
      </div>

      <div class="container">
        <gameserver-index-game-list />
      </div>

      <div class="index-page__bg-stars">
        <div class="container">
          <strapi-product-benefits
            :component="gameserverIndex.benefits"
            class="my-16"
            large-header
          />
        </div>
      </div>

      <strapi-product-faq
        data-cy="product-faq"
        :component="gameserverIndex.faq"
        class="mb-12"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  GetGameserverIndexDocument,
  type GetGameserverIndexQuery,
  type GetGameserverIndexQueryVariables,
  type StrapiGameserverIndex,
  type StrapiProduct,
  type TrustPilotScore,
} from '~/apollo/types/types';
import { getSmallestImageURL } from '~/helpers/strapiHelper';

const { t, locale } = useI18n();
const { $sentry } = useNuxtApp();
const md = useMarkdownIt();
const currencyStore = useCurrencyStore();
const config = useRuntimeConfig();

const currentCurrency = computed(() => currencyStore.currency);
const { refresh, data, error } = await useAsyncData<{
  products: Array<StrapiProduct>;
  gameserver: StrapiGameserverIndex;
  trustpilotScore: TrustPilotScore;
}>(`gp-gameserver-index-${locale.value}-${currentCurrency.value}`, async () => {
  const ret = {
    products: [] as Array<StrapiProduct>,
    gameserver: null as StrapiGameserverIndex,
    trustpilotScore: null as TrustPilotScore,
  };

  try {
    const { data: response } = await useLegacyApollo<
      GetGameserverIndexQuery,
      GetGameserverIndexQueryVariables
    >(GetGameserverIndexDocument, {
      currency: currentCurrency.value,
      locale: locale.value,
    });

    if (response.gameserver == null) {
      throw new Error(`No gameserver index found for locale ${locale.value}`);
    }

    ret.products = response.products as Array<StrapiProduct>;
    ret.gameserver = response.gameserver as StrapiGameserverIndex;
    ret.trustpilotScore = response.reviewScore as TrustPilotScore;

    return ret;
  } catch (e: unknown) {
    $sentry.captureException(e);
    throw new Error(e.toString());
  }
});

const { redirectToDefaultLocale } = useRedirectToLocale();
if (data.value.gameserver == null && locale.value !== 'en') {
  await redirectToDefaultLocale();
}

const gameserverIndex = computed(() => data.value.gameserver);
const topProducts = computed(() => data.value.products);
const trustpilotScore = computed(() => data.value.trustpilotScore);

const localizedSlugs = computed(() => gameserverIndex.value?.localizedSlugs);
useI18nLocalizedSlugs(localizedSlugs);

useHead({
  title: () => data.value.gameserver?.metaTitle,
  link: [
    {
      rel: 'preload',
      as: 'image',
      href: `/sc/images/svgs/landingpage_bg.svg`,
    },
  ],
});

const getSocialMediaImageUrl = () => {
  const trustPilotImage = data.value.gameserver?.trustpilot?.backgroundImage;
  if (trustPilotImage == null) {
    return undefined;
  }

  return {
    url: getSmallestImageURL(trustPilotImage),
  };
};
const route = useRoute();
useSeoMeta({
  title: () => data.value.gameserver?.metaTitle,
  description: () => data.value.gameserver?.metaDescription,
  ogTitle: () => data.value.gameserver?.metaTitle,
  ogDescription: () => data.value.gameserver?.metaDescription,
  ogImage: getSocialMediaImageUrl,
  ogUrl: `${config.public.websiteUrl}${route.fullPath}`,
  ogImageAlt: () => data.value.gameserver?.metaTitle,
  twitterTitle: () => data.value.gameserver?.metaTitle,
  twitterDescription: () => data.value.gameserver?.metaDescription,
  twitterImage: getSocialMediaImageUrl,
  twitterImageAlt: () => data.value.gameserver?.metaTitle,
});

const breadCrumbs = ref([
  { title: t('general/menu/item/game_servers'), link: '' },
]);

const { scrollToTop } = useWindowScroll();

useUserCurrency(refresh, () => {
  scrollToTop();
  return refresh();
});
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';
@import 'gportal-theme/scss/colors';

$header-transparent-bg-width: 43%;

.index-page {
  position: relative;
  color: #ffffff;

  &__trustpilot-logo {
    width: 150px;

    @include media-breakpoint-up(md) {
      width: 175px;
    }
  }

  &__background {
    width: 100%;
    position: relative;
    height: 16rem;
    z-index: -1;

    .img-fluid {
      width: 150%;
      max-width: none;
      overflow: hidden;
      margin-left: -50%;
    }

    @include media-breakpoint-up(md) {
      height: 100%;
      position: absolute;
      margin-top: 0;
      top: 0;

      .img-fluid {
        width: 100%;
        max-width: 100%;
        overflow: auto;
        margin-left: unset;
      }
    }

    img {
      mask-image: linear-gradient(to bottom, white 40%, transparent 90%);
    }
  }

  &__trustpilot-icons {
    width: 150px;
  }

  &__trustpilot-promo-text {
    max-width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 60%;
    }
  }

  &__head {
    &-inner {
      height: auto;

      @include media-breakpoint-up(md) {
        height: 24rem;
      }

      @include media-breakpoint-up(lg) {
        height: 28rem;
      }

      @include media-breakpoint-up(xxl) {
        height: 32rem;
      }
    }

    &-info {
      background: linear-gradient(
        to bottom,
        rgba($gp-midnight-gradient-a, 1) 0%,
        rgba($gp-midnight-gradient-b, 1) 150%
      );

      @include media-breakpoint-up(md) {
        z-index: 1;
        background: none;
      }
    }
  }

  &__top-games {
    z-index: 1;
  }

  &__bg-stars {
    background-image: url(/sc/images/svgs/landingpage_bg.svg);
    background-repeat: repeat;
    background-size: auto;
    background-attachment: fixed;
  }
}
</style>
