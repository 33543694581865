<template>
  <div class="game-list">
    <div class="row d-flex align-items-center pb-6">
      <div class="col-sm-12 col-md-12 col-lg-5">
        <h1
          v-if="selectedSearchType.type !== SearchTypes.SEARCH_PRODUCT_GROUP"
          data-cy="selected-sort-type"
        >
          {{ t(`landingpage/index/games/title/${selectedSearchType.key}`) }}
        </h1>
        <h1 v-else data-cy="selected-sort-type">
          {{ selectedSearchType.label }}
        </h1>
      </div>

      <div class="col-sm-12 col-md-4 col-lg-3 my-5 my-md-0">
        <form-dropdown
          :label="t('landingpage/index/games/sort_by')"
          :items="searchTypes"
          :item-class="'text-capitalize'"
          :value="selectedSearchType"
          @change="selectSearchType"
        />
      </div>

      <div
        class="col-sm-12 col-md-6 offset-md-2 offset-lg-0 col-lg-4 gamelist-searchbar"
      >
        <div class="input-group h-100">
          <span class="input-group-text">
            <fa-icon :icon="faMagnifyingGlass" />
          </span>
          <input
            v-model="searchString"
            data-cy="game-search-input"
            class="form-control py-3"
            :placeholder="t('landingpage/index/search')"
            type="text"
            :aria-label="t('landingpage/index/search')"
          />
        </div>
      </div>
    </div>

    <div class="row d-flex align-items-stretch">
      <div
        v-for="game in sortedGames"
        :key="game.id"
        class="col-lg-3 col-sm-6 col-12 py-3 px-12 p-1 p-lg-4"
      >
        <gameserver-index-game-card :game="game" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import {
  GetGamesDocument,
  type GetGamesQuery,
  type GetGamesQueryVariables,
  GetProductGroupsDocument,
  type GetProductGroupsQuery,
  type GetProductGroupsQueryVariables,
  type StrapiProduct,
  type StrapiProductGroup,
} from '~/apollo/types/types';

// This is a combination of sort and search filter
enum SearchTypes {
  // Sort by ... (no sorting result in A_TO_Z
  SEARCH_A_TO_Z = 1,
  SEARCH_Z_TO_A,

  // Filter search by group
  SEARCH_PRODUCT_GROUP,
}
interface SearchType {
  type: SearchTypes;
  key: string;
  label: string;
}
const { $sentry } = useNuxtApp();
const { t, locale } = useI18n();
const searchString = ref<string>();
const productGroupsFetched = ref(false);
const defaultSearchTypes = [
  {
    type: SearchTypes.SEARCH_A_TO_Z,
    key: 'a_to_z',
    label: t('landingpage/index/games/sort/dropdown/a_to_z').toString(),
  },
  {
    type: SearchTypes.SEARCH_Z_TO_A,
    key: 'z_to_a',
    label: t('landingpage/index/games/sort/dropdown/z_to_a').toString(),
  },
];
// searchTypes has to be a ssrRef here because otherwise using availableSearchTypes computed with searchTypes as regular ref will throw hydration error
const selectedSearchType = ref<SearchType>(defaultSearchTypes[0]);
// XXX: Maybe put this information inside strapi somewhere?
const searchTypeBlacklist = ['Footer', 'Advert'];

const currencyStore = useCurrencyStore();
const currentCurrency = computed(() => currencyStore.currency);
const { refresh, data: gameListData } = await useAsyncData(
  'gp-gameserver-index-game-list',
  async () => {
    const groups = [];
    if (selectedSearchType.value.type === SearchTypes.SEARCH_PRODUCT_GROUP) {
      groups.push(selectedSearchType.value.key);
    }

    const ret: {
      games: Array<StrapiProduct>;
      searchTypes: Array<SearchType>;
    } = {
      games: [],
      searchTypes: [],
    };

    try {
      const { data: response } = await useLegacyApollo<
        GetGamesQuery,
        GetGamesQueryVariables
      >(GetGamesDocument, {
        search:
          searchString.value != null && searchString.value !== ''
            ? searchString.value
            : null,
        locale: locale.value,
        groups,
        currency:
          currentCurrency.value.length === 0 ? 'EUR' : currentCurrency.value,
      });

      ret.games = response.products as Array<StrapiProduct>;

      if (!productGroupsFetched.value) {
        const res = await useLegacyApollo<
          GetProductGroupsQuery,
          GetProductGroupsQueryVariables
        >(GetProductGroupsDocument, {
          locale: locale.value,
        });

        const productGroups = res.data
          .productGroups as Array<StrapiProductGroup>;
        const additionalSearchTypes = productGroups
          .reverse()
          .filter((pg) => searchTypeBlacklist.indexOf(pg.groupKey) == -1)
          .map<SearchType>((pg) => ({
            type: SearchTypes.SEARCH_PRODUCT_GROUP,
            key: pg.groupKey,
            label: pg.slug,
          }));

        ret.searchTypes = [...defaultSearchTypes, ...additionalSearchTypes];
        productGroupsFetched.value = true;

        return ret;
      }
    } catch (error: unknown) {
      $sentry.captureException(error);
    }

    return ret;
  },
  {
    dedupe: 'defer',
  },
);

const games = computed<StrapiProduct[]>(() => {
  return gameListData.value.games;
});

const searchTypes = computed<SearchType[]>(() => {
  return gameListData.value.searchTypes;
});

useUserCurrency(refresh, refresh);

const debouncedSearch = useDebounceFn((v) => {
  refresh();
}, 150);

watch(() => searchString.value, debouncedSearch);

const selectSearchType = (st) => {
  selectedSearchType.value = st;
  refresh();
};

const sortedGames = computed<StrapiProduct[]>(() => {
  if (games.value == null) {
    return [];
  }

  switch (selectedSearchType.value.type) {
    case SearchTypes.SEARCH_Z_TO_A:
      games.value.sort((a, b) => b.name.localeCompare(a.name));
      break;
    default:
      games.value.sort((a, b) => a.name.localeCompare(b.name));
      break;
  }

  return games.value;
});
</script>

<style lang="scss" scoped>
@import 'gportal-theme/scss/colors.scss';

.game-list {
  .gamelist-searchbar {
    svg {
      color: $gp-legacy-blue-grey;
    }
  }
}
</style>
