<template>
  <fa-icon v-if="platform === StrapiPlatform.Pc" :icon="faWindows" />
  <fa-icon v-else-if="platform === StrapiPlatform.XBox" :icon="faXbox" />
  <fa-icon v-else-if="platform === StrapiPlatform.Ps4" :icon="faPlaystation" />
  <span v-else>{{ platform }}</span>
</template>

<script lang="ts">
import { StrapiPlatform } from '~/apollo/types/types';
import {
  faWindows,
  faXbox,
  faPlaystation,
} from '@fortawesome/free-brands-svg-icons';

export default defineComponent({
  props: {
    platform: {
      type: String as PropType<StrapiPlatform>,
      required: true,
    },
  },
  setup() {
    return {
      faWindows,
      faXbox,
      faPlaystation,

      StrapiPlatform,
    };
  },
});
</script>
